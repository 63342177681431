import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {checkToken} from '../user/reducer';

interface StateProps {
  activeCompanyInfo:any,
  searchCompanyList:Array<any>,
  searchHistoryList:Array<any>,
  loading: {
    [key: string]: boolean;
  },
  isCompanySearch:boolean, 
  isCompanySearching: boolean,
  company:{
    activedBusinessId: number;
    activedCompanyId: number;
    activedTagsBusinessId: Array<any>,
    dataList:{
      [key: number]: {
        dataList: Array<any>,
        total: number,
        page: number,
        hasMore?: boolean
      }
    },
  },
  searchLoading: boolean,
  searchStatus: boolean,
  listShow:boolean,
   eventDriven:any,
  socialMedia:any,
  policyDriven:any,
  aiList:any,
  resetListType:boolean,
  refreshData:any,
  aiRefreshTime:any,
  policyRefreshTime:any,
  swIndustry:Array<any>,
  industryRank:{
    industryRankList:Array<any>,
    updateTimestamp:any
  },
  userTrendData:{trend: any, trendData: any},
  userMedalData: {lsrsMedal: Array<any>, pmbmMedal: Array<any>},
  stockReport:Array<any>,
}

const initialState: StateProps = {
  activeCompanyInfo:{},
  searchCompanyList:[],
  searchHistoryList:[],
  loading: {},
  isCompanySearch: false,
  company: {
    activedBusinessId: 0,
    activedCompanyId: 0,
    activedTagsBusinessId: [],
    dataList: {},
  },
  isCompanySearching: false,
  searchLoading: false,
  searchStatus: false,
  listShow: false,
  eventDriven:{
    companyEventItemList:[],
    totalEventCount:0
  },
  socialMedia:{
    dataList:[],
    total:0
  },
  policyDriven:{
    total:0,
    dataList:[]
  },
  aiList:{
    total:0,
    dataList:[]
  },
  resetListType:false,
  refreshData:{
    companyScoreList:false,
    policyList:false
  },
  aiRefreshTime:'',
  policyRefreshTime:'',
  swIndustry:[],
  industryRank:{
    industryRankList:[],
    updateTimestamp:null
  },
  userTrendData:{trend: null, trendData: null},
  userMedalData: {lsrsMedal: [], pmbmMedal: []},
  stockReport:[]

}

export const searchCompany = createAsyncThunk(
  '/pandora-search/search-comapny',
  async (params:any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.searchNewCompany({...params, page: 0, size:30}))
)

export const searchCompanyInfo = createAsyncThunk(
  '/pandora-search/search-comapny-info',
  async (params:any, thunkAPI) => {
    const keyWords = params.keyWords
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await  pandora.searchCompanyInfo({...params, page: 0, size:10})
      return {
        keyWords:keyWords,
        ...result
      }
    })
  }
)




//事件驱动
export const getCompanyListWithEventRank = createAsyncThunk(
  'pandora-search/getCompanyListWithEventRank',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyListWithEventRank(params))
  }
)

//社交媒体统计
export const tocSocialMedia = createAsyncThunk(
  'pandora-search/tocSocialMedia',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.tocSocialMedia(params))
  }
) 

// 政策驱动
export const policyDrivenList = createAsyncThunk(
  'pandora-search/policyDrivenList',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.policyDrivenList(params))
  }
) 

//政策操作
export const adminOperate = createAsyncThunk(
  'pandora-search/adminOperate',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.adminOperate(payload))
);
// 事件置顶
export const markCompanyTop = createAsyncThunk(
  'pandora-search/markCompanyTop',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.markCompanyTop(payload))
);
// 事件隐藏
export const markCompanyVisible = createAsyncThunk(
  'pandora-search/markCompanyVisible',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.markCompanyVisible(payload))
);



// 获取公司信息
export const fetchGetCompanyInformation = createAsyncThunk(
  'pandora-search/fetchGetCompanyInformation',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetCompanyInformation(payload))
);

// 获取 ai 模型列表
export const getCompanyScoreRank = createAsyncThunk(
  'pandora-search/fetchgetCompanyScoreRank',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyScoreRank(payload))
);
//政策操作
export const updateCompanyScoreRankStatus = createAsyncThunk(
  'pandora-search/updateCompanyScoreRankStatus',
  async (payload:any,thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.updateCompanyScoreRankStatus(payload))
);

// 获取列表刷新状态
export const getCheckRefresh = createAsyncThunk(
  'pandora-search/getCheckRefresh',
  async (params:any, thunkAPI) => {
    const type = params.type
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getCheckRefresh(params)
      return {
        type:type,
        ...result
      }
    })
  }

);

export const fetchGetSwIndustry = createAsyncThunk(
  'pandora-search/fetch-get-sw-industry',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.querySwFirstLevel(params))
  }
) 
export const getIndustryRank = createAsyncThunk(
  'pandora-search/fetch-get-industryRank',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getIndustryRank(params))
  }
) 
export const getStockReport = createAsyncThunk(
  'pandora-search/fetch-get-stock-report',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getStockReport(params))
  }
) 

export const getUserMedal = createAsyncThunk(
  'pandora-search/fetch-getUserMedal',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getUserMedal(params))
  }
) 

export const getUserTrend = createAsyncThunk(
  'self-service/getUserTrend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getUserTrend(params))
  }
)

export const getUserMedalSwitch = createAsyncThunk(
  'pandora-search/getUserMedalSwitch',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getUserMedalSwitch(params))
  }
)
export const setUserMedalSwitch = createAsyncThunk(
  'pandora-search/setUserMedalSwitch',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.setUserMedalSwitch(params))
  }
)



export const pandoraSearch = createSlice({
  name:'pandoraSearch',
  initialState,
  reducers: {
    setActiveCompanyInfo: (state, {payload}) => {
      state.activeCompanyInfo = payload;
    },
    setIsCompanySearch: (state, {payload}) => {
      state.isCompanySearch = payload;
    },
    setNewSearchStatus: (state, {payload}) => {
      state.searchStatus = payload;
    },
    setCompanySearchStatus: (state, {payload}) => {
      state.isCompanySearching = payload;
    },
    setActiveCompany: (state, {payload}) => {
      state.company.activedCompanyId = payload;
    },
    setCompanyList: (state, {payload}) => {
      state.company.dataList = payload
    },setEventMarkedTop: (state, {payload}) => {
      const index = state.eventDriven.companyEventItemList.findIndex((item:any) => item.companyId === payload.companyId )
      if(payload.markedTop){
        const opObj = state.eventDriven.companyEventItemList[index]
        const untopIndex = state.eventDriven.companyEventItemList.findIndex((item:any) => item.markedTop === false )
        console.log('untopIndex',untopIndex)
        let arr = state.eventDriven.companyEventItemList
        arr.splice(index,1)
        opObj.markedTop = !payload.markedTop
        if(untopIndex === -1){
          arr.push(opObj); 
        }else{
          arr.splice(untopIndex-1, 0, opObj); 
        }
        state.eventDriven.companyEventItemList = arr
      }else{
        const opObj = state.eventDriven.companyEventItemList[index]
        opObj.markedTop = !payload.markedTop
        let arr = state.eventDriven.companyEventItemList
        arr.splice(index,1)
        arr.unshift(opObj)
        state.eventDriven.companyEventItemList = arr
      }
    },
    setEventHide: (state, {payload}) => {
      let arr = state.eventDriven.companyEventItemList
      arr.splice(state.eventDriven.companyEventItemList.findIndex((item:any) => item.companyId ===  payload.companyId), 1)
      state.eventDriven.companyEventItemList= arr
    },
    setAiMarkedTop: (state, {payload}) => {
      const index = state.aiList.dataList.findIndex((item:any) => item.id === payload.id )
      if(payload.top){
        const opObj = state.aiList.dataList[index]
        const untopIndex = state.aiList.dataList.findIndex((item:any) => !item.top )
        let arr = state.aiList.dataList
        arr.splice(index,1)
        opObj.top = 0
        if(untopIndex === -1){
          arr.push(opObj); 
        }else{
          arr.splice(untopIndex-1, 0, opObj); 
        }
        state.aiList.dataList = arr
      }else{
        const opObj = state.aiList.dataList[index]
        opObj.top = 1
        let arr = state.aiList.dataList
        arr.splice(index,1)
        arr.unshift(opObj)
        state.aiList.dataList = arr
      }
    },
    setAiHide: (state, {payload}) => {
      let arr = state.aiList.dataList
      arr.splice(state.aiList.dataList.findIndex((item:any) => item.id ===  payload.id), 1)
      state.aiList.dataList= arr
    },
    setPolicyMarkedTop: (state, {payload}) => {
      const index = state.policyDriven.dataList.findIndex((item:any) => item.id === payload.policyData.id )
      state.policyDriven.dataList[index].top = !payload.policyData.top
      const opObj = state.policyDriven.dataList[index]
      let arr = state.policyDriven.dataList
      arr.splice(index,1)
      arr.unshift(opObj)
      state.policyDriven.dataList = arr

    },
    setPolicyHide: (state, {payload}) => {
      let arr = state.policyDriven.dataList
      arr.splice(state.policyDriven.dataList.findIndex((item:any) => item.id ===  payload.policyData.id), 1)
      state.policyDriven.dataList= arr
    },
    resetPolicyDrivenList: (state, {payload}) => {
      state.policyDriven = {total:0,dataList:[] }
    },
    resetEventDrivenList: (state, {payload}) => {
      state.eventDriven = {companyEventItemList:[],totalEventCount:0}
    },
    resetAiList: (state, {payload}) => {
      state.aiList = {dataList:[],total:0}
    },
    setResetListType: (state, {payload}) => {
      state.resetListType = payload
    },
    setAiRefreshTime: (state, {payload}) => {
      state.aiRefreshTime = payload
      state.refreshData.companyScoreList = false
    },
    setPolicyRefreshTime: (state, {payload}) => {
      state.policyRefreshTime = payload
      state.refreshData.policyList = false

    },

    
   
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompany.pending, (state) => {
      state.loading.searchLoading = true;
    }).addCase(searchCompanyInfo.pending, (state) => {
      state.loading.searchLoading = true;
      state.searchCompanyList= []
    }).addCase(searchCompanyInfo.fulfilled, (state, {payload}) => {
      const {data,keyWords, code} = payload;
      if(code === '0'){
        if(data.historyList.length){
          data.historyList.forEach((item:any)=>{
            item.keyword = keyWords;
            item.type = '常搜';
          })
        }
        state.searchCompanyList = Object.entries(data).map((recommend: [string, any]) => (recommend[1] as Array<any>).map(item => ({
          ...item, 
          type: recommend[0] ==='searchList' ? ''  : '常搜'
        }))).flat();
        state.searchHistoryList = data.historyList;
      }
      state.loading.searchLoading = false;
    }).addCase(getCompanyListWithEventRank.pending, (state) => {
      state.loading['getCompanyListWithEventRank'] = true;
    }).addCase(getCompanyListWithEventRank.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.eventDriven = data
        state.loading['getCompanyListWithEventRank'] = false;
      }
    }).addCase(tocSocialMedia.pending, (state) => {
      state.loading['tocSocialMedia'] = true;
    }).addCase(tocSocialMedia.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        data.dataList.forEach((item:any) => {
          item.zm = item.longNum ? Number(item.longNum.replace('%','')):0
          item.zl = item.neutralName ? Number(item.neutralName.replace('%','')):0
          item.fm = item.shortNum ? Number(item.shortNum.replace('%','')):0
          
        });
        state.socialMedia = data

        state.loading['tocSocialMedia'] = false;
      }
    }).addCase(policyDrivenList.pending, (state) => {
      state.loading['policyDrivenList'] = true;
    }).addCase(policyDrivenList.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.policyDriven.total = data.total
        state.policyDriven.dataList = state.policyDriven.dataList.concat(data.dataList)
        state.loading['policyDrivenList'] = false;
      }
    }).addCase(getCompanyScoreRank.pending, (state) => {
      state.loading['getCompanyScoreRank'] = true;
    }).addCase(getCompanyScoreRank.fulfilled, (state, {payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.aiList.total = data.total
        state.aiList.dataList = state.aiList.dataList.concat(data.dataList)
        state.loading['getCompanyScoreRank'] = false;
      }
    }).addCase(getCheckRefresh.fulfilled, (state, {payload}) => {
      const {code,type,data} = payload;
      if(code === '0'){
        if(type === 'ai'){
          state.refreshData.companyScoreList = data.companyScoreList
        }else{
          state.refreshData.policyList = data.policyList
        }
      }
    }).addCase(fetchGetSwIndustry.pending, (state) => {
      state.loading.fetchGetSwIndustry = true;
    }).addCase(fetchGetSwIndustry.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        if(!data.length){
          state.swIndustry =[]
          state.loading.fetchGetSwIndustry = false;
          return
        }
        state.swIndustry = ['不限'].concat(data)
        state.loading.fetchGetSwIndustry = false;
      }
    }).addCase(getIndustryRank.pending, (state) => {
      state.loading.getIndustryRank = true;
    }).addCase(getIndustryRank.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.industryRank = data
        state.loading.getIndustryRank = false;
      }
    }).addCase(getUserTrend.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.userTrendData = data
      }
    }).addCase(getUserMedal.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.userMedalData = data
      }
    }).addCase(getStockReport.pending, (state) => {
      state.loading.getStockReport = true;
    }).addCase(getStockReport.fulfilled, (state, { payload }) => {
      const {data,code} =payload;
      if(code === '0'){
        state.stockReport = data
        state.loading.getStockReport = false;
      }
    })


    
  }
})

export const {setPolicyRefreshTime, setAiRefreshTime, resetAiList, setAiMarkedTop,setAiHide,resetEventDrivenList ,resetPolicyDrivenList, setPolicyHide,setEventHide, setPolicyMarkedTop, setEventMarkedTop,setActiveCompany,
  setCompanyList, setCompanySearchStatus, setNewSearchStatus, setIsCompanySearch, setActiveCompanyInfo,setResetListType}  = pandoraSearch.actions;

const selectBaseState = (state: RootState)  => state.pandoraSearch;
export const selectActiveCompanyInfo = createSelector(selectBaseState, state => state.activeCompanyInfo || {})
export const selectSearchCompanyList = createSelector(selectBaseState, state => state.searchCompanyList || [])
export const selectSearchHistoryList = createSelector(selectBaseState, state => state.searchHistoryList || [])
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectIsCompanySearch = createSelector(selectBaseState, state => state.isCompanySearch || false)
export const selectActivedCompanyId = createSelector(selectBaseState,  state => state.company.activedCompanyId || '')

export const selectCompanySearchingStatus = createSelector(selectBaseState, state => state.isCompanySearching || false)
export const selectSearchStatus = createSelector(selectBaseState, state => state.searchStatus || false)
export const selectListShow = createSelector(selectBaseState, state => state.listShow || false)

export const selectSearchLoading = createSelector(selectBaseState, state => state.searchLoading)

export const selectEventDriven = createSelector(selectBaseState, state => state.eventDriven)
export const selectSocialMedia = createSelector(selectBaseState, state => state.socialMedia)
export const selectPolicyDriven = createSelector(selectBaseState, state => state.policyDriven)
export const selectAiList = createSelector(selectBaseState, state => state.aiList)
export const selectResetListType = createSelector(selectBaseState, state => state.resetListType)
export const selectRefreshData = createSelector(selectBaseState, state => state.refreshData)
export const selectAiRefreshTime = createSelector(selectBaseState, state => state.aiRefreshTime)
export const selectPolicyRefreshTime = createSelector(selectBaseState, state => state.policyRefreshTime)
export const selectSwIndustry = createSelector(selectBaseState, state => state.swIndustry || [])
export const selectIndustryRank = createSelector(selectBaseState, state => state.industryRank || {industryRankList:[],updateTimestamp:null})
export const selectUserTrendData = createSelector(selectBaseState, state => state.userTrendData)
export const selectUserMedalData = createSelector(selectBaseState, state => state.userMedalData)
export const selectStockReport = createSelector(selectBaseState, state => state.stockReport)



export default pandoraSearch.reducer;

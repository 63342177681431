import { Axios } from 'axios';
import defaults from 'axios/lib/defaults';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

const needlessTokenApis = ['/pandora-app-bff/login', '/pandora-app-bff/register', '/pandora-app-bff/resetpassword', '/pandora-app-bff/thememap', '/pandora-app-bff/news/daily_flash_list']

const bffBaseURL =  process.env.REACT_APP_BFF_URL;
class Pandora extends Axios {
  constructor(config = {}){
    super({...defaults, ...config});
    this.token = config.token || '';
        this.userId = '' || config.tokenInfo?.uid;
    // this.language = toc ? 'zh-CN' : config.language || 'en';
    this.language = 'zh-CN'
    this.interceptors.request.use(request => {
      request.baseURL = bffBaseURL;
      request.headers = {
        ...request.headers,
        ...config.headers,
      }
      if (this.token && !needlessTokenApis.includes(request?.url || '')) {
        request.headers = {
          ...request.headers,
          language: this.language,
          'token': request.headers.token ? request.headers.token : this.token,
          authorization: `Bearer ${request.headers.token ? request.headers.token : this.token}`,
          'deviceType': 'WEB',
        };
      }
      return request;
    });
    this.interceptors.response.use(async response => {
      return response.data;
    })
  }



  setToken(token){
    this.token = token;
  }

  setRefreshToken(token){
    this.rsToken = token;
  }

  setTokenInfo(payload){
    this.tokenInfo = payload;
  }

  checkToken(){
    return this.post(`/pandora-company-bff/checkTocToken`, {}).then(response => {
      const {code,data} = response;
      if(code === '0'){
        return Promise.resolve(data);
      }
    })
  }


  login(values){
    return this.post(`/pandora-app-bff/login/v2`, values).then(response => {
      const {success, result} = response;
      if(success){
        this.setTokenInfo({...this.tokenInfo, ...result});
        this.setToken(result.token)
        this.setRefreshToken(result.refresh_token);
      }
      return response;
    })
  }

  refreshToken(payload){
    return this.post('/pandora-app-bff/refreshtoken', payload).then(response => {
      const {success, result} = response;
      if(success){
        this.setTokenInfo({...this.tokenInfo, ...result});
        this.setToken(result.token)
        this.setRefreshToken(result.refresh_token);
      }
      return response;
    })
  }

  logout(){
    this.token = ''
    this.tokenInfo = {};
    this.rsToken = '';
  }

  fetchUserInfo(){
    return this.get(`/pandora-app-bff/userinfo`).then(response => {
      const {success, result} = response;
      if(success){
        this.userId = result.data.uid;
        this.language = result.data.language;
      }
      return response;
    })
  }

  updateUserConfig(payload){
    return this.post(`/pandora-app-bff/user/updateConfig`, payload)
  }

  updatePassword(payload){
    return this.post(`/pandora-user-bff/updatePassword`, payload)
  }

  fetchThemeMap({themeId, token}){
    return this.get(`/pandora-app-bff/theme/${themeId}/thememap`, {headers:{token: token ? token : this.token}})
  }

  fetchDailyFlashNews(payload){
    return this.get(`/pandora-app-bff/news/daily_flash_list`, {params:{direction: 'down', page_size: 20, ptime: `${payload.date} ${payload.timePoint}`},headers:{token: this.token, language: this.language}}).then((response) => {
      const {result} = response;
      const requestMap = result.map((item) => this.fetchListDetails(item.news_id_list))
      return Promise.all(requestMap).then((response) => {
        return result.map((item, index) => {
          return {
            ...item,
            details: response[index].data
          }
        })
      });
    }).catch(err => {
      return Promise.reject(err)
    })
  }

  fetchListDetails(ids){
    return this.post(`/os-pandora-bff/news/dailyFlash/newsList`, { newsIdList: ids, headers: {token: this.token}})
  }

  fetchDetailByNewsId(payload){
    return this.post(`/os-pandora-bff/news/detail`, {...payload, userId: this.userId, requestId: uuidv4()})
  }

  register(values){
    return this.post(`/pandora-app-bff/register`, values)
  }

  getverifycode(query){
    return this.get(`/pandora-app-bff/getverifycode?${query}`);
  }
  checkverifycode(query){
    return this.get(`/pandora-app-bff/checkverifycode?${query}`);
  }
  
  getActiveCode(payload){
    return this.post(`/pandora-app-bff/getactivateCode`, payload);
  }

  resetSendmail(values){
    return this.post(`/pandora-app-bff/resetpasswd/sendmail`, values)
  }
  resetPassword(values){
    return this.post(`/pandora-app-bff/resetpasswd`, values)
  }
  resetCheckcode(values){
    return this.post(`/pandora-app-bff/resetpasswd/checkCode`, values)
  }

  fetchThemes(query){
    return this.get(`/pandora-app-bff/themes?${query}`).then(async (resp) => {
      const {result} = resp;
      const fetchCompanyListRequest = result.data_list.map(theme => this.fetchThemeById({themeId: theme.theme_id, size: 3, page:0}))
      await Promise.all(fetchCompanyListRequest).then(themeDetailResp => {
        result.data_list.forEach((item, index) => {
          result.data_list[index] = {
            ...result.data_list[index],
            opportunity_count:themeDetailResp[index].data.opportunityTotal,
            risk_count:themeDetailResp[index].data.riskTotal,
            total:themeDetailResp[index].data.total,

            companies: themeDetailResp[index].data.dataList
          }
        })
      })
      return resp;
    });
  }
  
  fetchThemeById(payload){
    return this.post('/pandora-company-bff/getCompanyList', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchCompanyOpportunities(id){
    return this.get(`/pandora-app-bff/theme/thememap/company/${id}`)
  }

  fetchNewCompanyIdByOldCompanyId(payload){
    return this.post(`/pandora-company-bff/findCompanyIdByOldCompanyId`, {...payload, requestId: uuidv4()})
  }

  fetchEventSummary(payload){
    const { id } = payload;
    return Promise.all([this.fetchEventEntities(id), this.fetchEventEntityGraph(id), this.fetchEventDetails(payload)]).then(async resp => {
      const [, , details] = resp;
      if(!details.result){
        return resp;
      }
      const {data = []} = await this.fetchEventTypeImg({eventType: details.result.event_type})
      details.result.eventTypeImg = data[0]
      return resp;
    });
  } 

  fetchEventEntities(id){
    return this.post(`/os-pandora-bff/event/entities`, {id, userId: this.userId, requestId: uuidv4()})
  }

  fetchEventEntityGraph(id){
    return this.get(`/pandora-app-bff/event/${id}/entity_graph`)
  }
  
  fetchEventDetails(payload){
    const {id, company_id} = payload;
    return this.get(`/pandora-app-bff/company/${company_id}/events?event_id=${id}`)
  }

  fetchCompanyList(query){
    return this.get(`/pandora-app-bff/company_name/list?${query}`)
  }

  startedCompany(payload){
    return this.post(`/os-pandora-bff/addWatchList`, {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  unstarCompany(payload){
    return this.post('/os-pandora-bff/removeWatchList', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  addOrCancelUserWatchList(payload){
    return this.post('/pandora-company-bff/addUserWatchList', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  fetchLatestEvents(payload){
    return this.post(`/pandora-event-bff/getLatestEventList`, {...payload, userId: this.userId, requestId: uuidv4()})

    // return this.post(`/os-pandora-bff/latestEventGroup`, {...payload, userId: this.userId, requestId: uuidv4()})
  }

  searchCompany(payload){
    return this.post('/os-pandora-bff/searchCompany', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  searchBusiness(payload){
    return this.post('/os-pandora-bff/searchBusiness', {...payload, userId: this.userId,requestId: uuidv4()})
  }

  fetchCompanyBusinessList(payload){
    return this.post('/os-pandora-bff/getBusinessTypePage', {...payload, requestId: uuidv4()})
  }

  fetchBusinessList(payload){
    return this.post('/os-pandora-bff/getFinancialReport', {...payload, requestId: uuidv4()})
  }

  fetchBusinsessReport(payload){
    return this.post('/os-pandora-bff/getCompanyReportBusiness', {...payload, requestId: uuidv4()}).then(async resp => {
      const {data} = resp;
      const {data: {url}} = await this.fetchReportUrl({fragmentId: data.fragmentId});
      return {
        ...resp,
        data: {
          ...resp.data,
          url: url,
        }
      }
    })
  }

  addComment(payload){
    return this.post('/os-pandora-bff/addComment', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  deleteComment(payload){
    return this.post('/os-pandora-bff/deleteComment', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  fetchReportUrl(payload){
    return this.post('/os-pandora-bff/getReportDownloadUrl', {...payload, requestId: uuidv4()})
  }
  
  fetchCompanyByBusiness(payload){
    return this.post('/os-pandora-bff/searchCompanyByBusiness', {...payload, requestId: uuidv4()})
  }
  
  fetchExchangeList(payload){
    return this.post('/os-pandora-bff/theme/exchange', {...payload, page: 0, size: 100, requestId: uuidv4()})
  }

  fetchUserDailyFlashTimePoints(){
    return this.get('/pandora-app-bff/news/select_time_point');
  }

  addUserDailyFlashTimePoint(payload){
    return this.post('/pandora-app-bff/news/add_time_point', payload);
  }
  deleteUserDailyFlashTimePoint(){
    return this.get('/pandora-app-bff/news​/del_time_point');
  }
  
  fetchCompanyStockPrice(payload){
    return this.get(`/os-pandora-bff/getCompanyStockPrice?${qs.stringify({...payload, requestId: uuidv4()})}`)
  }
  
  fetchCompanyStockEvent(payload){
    return this.post('/os-pandora-bff/getCompanyEvent', {...payload, requestId: uuidv4()})
  }

  saveFloor(payload){
    return this.post('/pandora-floor-bff/floor/saveFloorSnapshot', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  updateSnapshotFloor(payload){
    return this.post('/pandora-floor-bff/floor/updateFloorSnapshot', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  deleteFloor(payload){
    return this.post('/pandora-floor-bff/floor/deleteFloor', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  duplicateFloor(payload){
    return this.post('/pandora-floor-bff/floor/duplicateFloor', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  saveMasterFloor(payload){
    return this.post('/pandora-floor-bff/floor/saveMasterFloor', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  batchOpenFloor(payload){
    return this.post('/pandora-floor-bff/floor/batchOpenFloor', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  updateFloor(payload){
    return this.post('/pandora-floor-bff/floor/updaterFloor', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  getFloorList(payload){
    return this.post('/pandora-floor-bff/floor/getFloorList', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }
  
  getOpenFloorList(payload){
    return this.post('/pandora-floor-bff/floor/getOpenFloorList', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }

  getMasterFloorIdList(payload){
    return this.post('/pandora-floor-bff/floor/getMasterFloorIdList', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  getRoomData(){
    return this.post('/pandora-floor-bff/room/getRoomData', {userId: this.userId, requestId: uuidv4()})
  }
  getRoomDetail(payload){
    return this.post('/pandora-floor-bff/room/getRoomDetail', {...payload,userId: this.userId, requestId: uuidv4()})
  }
  
  getSubscribeRoom(payload){
    return this.post('/pandora-floor-bff/room/getSubscribeRoomList', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  roomSubscribe(payload){
    return this.post('/pandora-floor-bff/room/roomSubscribe', {...payload, userId: this.userId, requestId: uuidv4()})
  }

  addFloorRoom(payload){
    return this.post('/pandora-floor-bff/floor/addFloorRoom', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }

  addRoom(payload){
    return this.post('/pandora-floor-bff/room/addRoomData', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }

  fetchFloorDetails(payload){
    return this.post('/pandora-floor-bff/floor/getFloorDetail', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }
  
  updateFloorLayouts(payload){
    return this.post('/pandora-floor-bff/floor/updateFloorRoomLayouts', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }

  updateFloorRoomMetadata(payload){
    return this.post('/pandora-floor-bff/floor/updateFloorRoomMetadata', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }

  deleteFloorRoom(payload){
    return this.post('/pandora-floor-bff/floor/deleteFloorRoom', {...payload, client: "pc", userId: this.userId, requestId: uuidv4()})
  }
  
  setUserLanguage(payload){
    return this.post('/pandora-app-bff/user/updateLanguage', payload).then(resp => {
      this.language = payload.data
      return resp;  
    })
  }
  
  fetchCompanyProfile(payload){
    return this.post('/os-pandora-bff/getCompanyProfile', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchCompanyInformation(payload){
    return Promise.all([
      this.post('/os-pandora-bff/getCompanyInformation', {...payload, requestId: uuidv4(), userId: this.userId}), 
      this.fetchCompanyInsightsType({size: 10})
    ])
  }

  fetchCompanyInsightsType(payload){
    return this.post('/os-pandora-bff/getCompanyInsightsEventTypeList', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchCompanyInsights(payload){
    return this.post('/os-pandora-bff/getCompanyInsights', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchIndustryInformation(payload){
    return this.post('/os-pandora-bff/getIndustryDetail', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchNodeInformation(payload){
    return this.post('/os-pandora-bff/getThemeNodeInfo', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchNodeIndexCategory(){
    return this.post('/os-pandora-bff/getNodeIndexCategory', {requestId: uuidv4(), userId: this.userId})
  }

  fetchNodeIndexRation(payload){
    return this.post('/os-pandora-bff/getNodeIndexRation', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchIndustryTopCompanyMarketCap(payload){
    return this.post('/os-pandora-bff/getCompanyMarketCap', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchIndustryIndex(payload){
    return Promise.all([this.fetchIndex(payload), this.fetchIndex({...payload, id: -1})])
  }

  fetchIndex(payload){
    return this.post('/os-pandora-bff/getIndustryIndex', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchEventTypeImg(payload){
    return this.post('/os-pandora-bff/getStockEventGraph', {...payload, requestId: uuidv4()})
  }
  
  getCompanyNopat(payload){
    return this.post('/os-pandora-bff/getCompanyNopat', {...payload, requestId: uuidv4()})
  }

  fetchRelatedNewsDetails(payload){
    const {newsId, isWatch = true} = payload
    return this.post('/os-pandora-bff/getNewsDetailData',{...payload, userId: this.userId, requestId: uuidv4()}).then(async resp => {
      const result = await this.getCompanyListByNewsId({isWatch: isWatch, newsId: newsId})
      if(!result.code){
        return {
          ...resp,
          data:{
            ...resp.data,
            companyList: result.data.companyList
          }
        }
      }else{
        return resp
      }
    })
  }

  getCompanyListByNewsId(payload){
    return this.post('/os-pandora-bff/getCompanyListByNewsId',{...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  fetchWatchList(payload){
    return this.post('/os-pandora-bff/getCompanyListByUserId',{...payload, userId: this.userId, requestId: uuidv4()})
  }

  fetchNewsByCompanyId(payload){
    return this.post('/os-pandora-bff/news', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  
  fetchCompanyProduct(payload){
    return this.post('/os-pandora-bff/getCompanyProduct', {...payload, requestId: uuidv4()})
  }

  fetchPandemicData(payload){
    return this.post('/os-pandora-bff/getPandemicData', {...payload, requestId: uuidv4()})
  }

  fetchPortfolioRiskCompany(payload){
    return this.post('/os-pandora-bff/portfolioRiskCompanyList', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  
  fetchTopicHistoryNews(payload){
    return this.post('/os-pandora-bff/getHistoryNews', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchTopicTopTenNews(payload){
    return this.post('/os-pandora-bff/getTopicHistoryNews', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchTopicHotNews(payload){
    return this.post('/os-pandora-bff/getTopicHotNews', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchCountryRelation(payload){
    return this.post('/os-pandora-bff/countryRelation', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  sendInviteEmail(payload){
    return this.post('/pandora-app-bff/user/invite', payload)
  }

  fetchTopicCloud(payload){
    return this.post('/os-pandora-bff/getTopicCloud', {...payload, requestId: uuidv4()})
  }
  fetchTopicSentimentTrend(payload){
    return this.post('/os-pandora-bff/getTopicSentimentTrend', {...payload, requestId: uuidv4()})
  }
  fetchTopicHotTrend(payload){
    return this.post('/os-pandora-bff/getTopicHotTrend', {...payload, requestId: uuidv4()})
  }

  fetchCompanyInfluenceByTopic(payload){
    return this.post('/os-pandora-bff/getCompanyInfluenceByTopic', {...payload, requestId: uuidv4(), userId: this.userId})
  }

  fetchSendMailStatus(payload){
    return this.post('/pandora-user-bff/getUserSendMailStatus', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchSaveSendMailStatus(payload){
    return this.post('/pandora-user-bff/saveSendMailStatus', {...payload, requestId: uuidv4()})
  }
  fetchHeJunNews(payload){
    return this.post('/pandora-news-bff/news/fetchHeJunNews', {...payload, requestId: uuidv4()})
  }
  fetchHeJunWeeklyReports(payload){
    return this.post('/pandora-news-bff/news/fetchHeJunWeeklyReports', {...payload, requestId: uuidv4()})
  }

  fetchSearchNode(payload){
    return this.post('/pandora-search-bff/searchNode', {...payload, requestId: uuidv4()})
  }
  fetchNodeKeywordMatch(payload){
    return this.post('/pandora-search-bff/nodeKeywordMatch', {...payload, requestId: uuidv4()})
  }
  fetchGetSubNodeTypes(payload){
    return this.post('/pandora-search-bff/getSubNodeTypes', {...payload, requestId: uuidv4()})
  }
  fetchGetNodeList(payload){
    return this.post('/pandora-search-bff/getNodeList', {...payload, requestId: uuidv4()})
  }
  fetchGetNodeDetail(payload){
    return this.post('/pandora-search-bff/getNodeDetail', {...payload, requestId: uuidv4()})
  }

  fetchGetTopStockCompany(payload){
    return this.post('/pandora-noyaxe-bff/getTopStockCompany', {...payload, requestId: uuidv4()})
  }
  fetchGetAuthorArticleList(payload){
    return this.post('/pandora-noyaxe-bff/getAuthorArticleList', {...payload, requestId: uuidv4()})
  }

  fetchGetAuthorPage(){
    return this.post('/pandora-noyaxe-bff/getAuthorPage', {requestId: uuidv4()})
  }
  fetchGetAuthorDetail(payload){
    return this.post('/pandora-noyaxe-bff/getAuthorDetail', {...payload,requestId: uuidv4()})
  }

  fetchGetNewFansAndPolarity(payload){
    return this.post('/pandora-noyaxe-bff/getNewFansAndPolarity', {...payload,requestId: uuidv4()})
  }
  fetchGetUpdateFrequency(payload){
    return this.post('/pandora-noyaxe-bff/getUpdateFrequency', {...payload,requestId: uuidv4()})
  }
  fetchGetAccumulatedFansNum(payload){
    return this.post('/pandora-noyaxe-bff/getAccumulatedFansNum', {...payload,requestId: uuidv4()})
  }
  fetchGetAuthorArticleReadCommentNum(payload){
    return this.post('/pandora-noyaxe-bff/getAuthorArticleReadCommentNum', {...payload,requestId: uuidv4()})
  }
  fetchGetOverviewArticleStatistics(payload){
    return this.post('/pandora-noyaxe-bff/getOverviewArticleStatistics', {...payload,requestId: uuidv4()})
  }
  fetchGetOverviewPolarityStatistics(payload){
    return this.post('/pandora-noyaxe-bff/getOverviewPolarityStatistics', {...payload,requestId: uuidv4()})
  }
  fetchGetBarPolarityStatistics(payload){
    return this.post('/pandora-noyaxe-bff/getBarPolarityStatistics', {...payload,requestId: uuidv4()})
  }
  fetchGetOverviewAuthorHotTrend(payload){
    return this.post('/pandora-noyaxe-bff/getOverviewAuthorHotTrend', {...payload,requestId: uuidv4()})
  }
  fetchGetMacro(payload){
    return this.post('/pandora-noyaxe-bff/getMacro', {...payload,requestId: uuidv4()})
  }
  fetchGetHeatCurve(payload){
    return this.post('/pandora-noyaxe-bff/getHeatCurve', {...payload,requestId: uuidv4()})
  }
  fetchGetOtherCurve(payload){
    return this.post('/pandora-noyaxe-bff/getOtherCurve', {...payload,requestId: uuidv4()})
  }
  fetchGetTradingVolume(payload){
    return this.post('/pandora-noyaxe-bff/getTradingVolume', {...payload,requestId: uuidv4()})
  }
  fetchGetTransactionPrice(payload){
    return this.post('/pandora-noyaxe-bff/getTransactionPrice', {...payload,requestId: uuidv4()})
  }
  fetchFindArticleAndCommentByBarId(payload){
    return this.post('/pandora-noyaxe-bff/findArticleAndCommentByBarId', {...payload,requestId: uuidv4()})
  }
  fetchGetPersistenceCurvee(payload){
    return this.post('/pandora-noyaxe-bff/getPersistenceCurve', {...payload,requestId: uuidv4()})
  }
  fetchGetIndustryBar(){
    return this.post('/pandora-noyaxe-bff/getIndustryBar', {requestId: uuidv4()})
  }
  fetchGetIndustryBarPostStatistics(payload){
    return this.post('/pandora-noyaxe-bff/getIndustryBarPostStatistics', {...payload,requestId: uuidv4()})
  }
  fetchGetPostStatistics(payload){
    return this.post('/pandora-noyaxe-bff/getPostStatistics', {...payload,requestId: uuidv4()})
  }
  fetchGetWordCloud(payload){
    return this.post('/pandora-noyaxe-bff/getWordCloud', {...payload, requestId: uuidv4()})
  }
  fetchSearchStockCompany(payload){
    return this.post('/pandora-noyaxe-bff/searchStockCompany', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyDetail(payload){
    return this.post('/pandora-noyaxe-bff/getCompanyDetail', {...payload, requestId: uuidv4()})
  }
  fetchGetStockCompanyHeatGraphList(){
    return this.post('/pandora-noyaxe-bff/getStockCompanyHeatGraphList', {requestId: uuidv4()})
  }
  fetchGetStockCompanyHeatGraphDetail(payload){
    return this.post('/pandora-noyaxe-bff/getStockCompanyHeatGraphDetail', {...payload, requestId: uuidv4()})
  }

  
  // 供应链加的
  fetchGetCompanyList(payload){
    return this.post('/pandora-company-bff/getCompanyList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetNewsByCompanyId(payload){
    return this.post('/pandora-news-bff/news/getNews', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetEventDataListByCompany(payload){
    return this.post('/pandora-event-bff/getEventDataListByCompany', {...payload, requestId: uuidv4()})
  }
  getEventDataListByCompanyId(payload){
    return this.post('/pandora-event-bff/getEventDataListByCompanyId', {...payload, requestId: uuidv4()})
  }


  fetchGetEventDataListByCompanyEventOne(payload){
    return this.post('/pandora-event-bff/getEventDataListByCompanyEvent', {...payload, requestId: uuidv4()})
  }
  fetchGetEventDetail(payload){
    return this.post('/pandora-event-bff/getEventDetail', {...payload, requestId: uuidv4()})
  }
  fetchGetTopCompanyEvent(payload){
    return this.post('/pandora-event-bff/getTopCompanyEvent', {...payload, requestId: uuidv4()})
  }
  fetchGetNewsDetail(payload){
    return this.post('/pandora-news-bff/news/getNewsDetail', {...payload, requestId: uuidv4()})
  }  
  fetchGetCompanyNameListByNewsIdOrEventId(payload){
    return this.post('/pandora-company-bff/getCompanyNameListByNewsIdOrEventId', {...payload, requestId: uuidv4(),userId: this.userId})
  }  
  fetchGetCompanyInformation(payload){
    return this.post('/pandora-company-bff/getCompanyInformation', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyAnnouncement(payload){
    return this.post('/pandora-company-bff/getCompanyAnnouncement', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyInsights(payload){
    return this.post('/pandora-company-bff/getCompanyInsights', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetCompanyInsightsEventTypeList(payload){
    return this.post('/pandora-company-bff/getCompanyInsightsEventTypeList', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyMainBusinessTurnover(payload){
    return this.post('/pandora-company-bff/getCompanyMainBusinessTurnover', {...payload, requestId: uuidv4()})
  }
  fetchPortfolioCompanyStockEvent(payload){
    return this.post('/pandora-price-event-bff/getCompanyEvent', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyQccEventList(payload){
    return this.post('/pandora-event-bff/getCompanyQccEventList', {...payload, requestId: uuidv4()})
  }
  fetchGetQccEventDetail(payload){
    return this.post('/pandora-event-bff/getQccEventDetail', {...payload, requestId: uuidv4()})
  }

  fetchGetSupplyChainDetail(payload){
    return this.post('/pandora-supply-chain-bff/getSupplyChainDetail', {...payload, requestId: uuidv4()})
  }
  fetchGetSupplyChainList(){
    return this.post('/pandora-supply-chain-bff/getSupplyChainList', { requestId: uuidv4(),userId: this.userId})
  }
  fetchQccRunSpider(payload){
    return this.post('/pandora-supply-chain-bff/qccRunSpider', {...payload, requestId: uuidv4()})
  }
  fetchSaveSupplyChainData(payload){
    return this.post('/pandora-supply-chain-bff/saveSupplyChainData', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetSupplyChainCompanyList(payload){
    return this.post('/pandora-company-bff/getSupplyChainCompanyList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSupplyChainSearchCompany(payload){
    return this.post('/pandora-company-bff/supplyChainSearchCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetUserSupplyChainOperation(){
    return this.post('/pandora-user-bff/getUserSupplyChainOperation', {requestId: uuidv4()})
  }
  fetchSaveUserSupplyChainOperationData(payload){
    return this.post('/pandora-user-bff/saveUserSupplyChainOperationData', {...payload,requestId: uuidv4()})
  }
  fetchGetSupplyChainCompanyEvent(payload){
    return this.post('/pandora-supply-chain-bff/getSupplyChainCompanyEvent', {...payload, requestId: uuidv4()})
  }
  fetchGetSupplyChainQueryRiskType(payload){
    return this.post('/pandora-supply-chain-bff/queryRiskType', {...payload, requestId: uuidv4()})
  }
  fetchGetReportEventRisk(payload){
    return this.post('/pandora-supply-chain-bff/reportEventRisk', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetConsultantExpert(payload){
    return this.post('/pandora-supply-chain-bff/consultantExpert', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchQueryCompanyParts(payload){
    return this.post('/pandora-supply-chain-bff/queryCompanyParts', {...payload, requestId: uuidv4()})
  }
  fetchDownloadSupplyChainExcelTemplate(){
    return this.post('/pandora-supply-chain-bff/downloadSupplyChainExcelTemplate', {requestId: uuidv4()})
  }
  fetchUploadSupplyChainExcele(payload){
    return this.post('/pandora-supply-chain-bff/uploadSupplyChainExcel',{...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSearchUserCompanyRelation(payload){
    return this.post('/pandora-supply-chain-bff/searchUserCompanyRelation',{...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSaveUserCompanyRelation(payload){
    return this.post('/pandora-supply-chain-bff/saveUserCompanyRelation',{...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchDeleteSupplyChainById(payload){
    return this.post('/pandora-supply-chain-bff/deleteSupplyChainById',{...payload, requestId: uuidv4(),userId: this.userId})
  }
// 供应链加的结束

fetchGetEventDataListByCompanyEvent(payload){
    return this.post('/pandora-event-bff/getEventDataListByCompanyEvent', {...payload, requestId: uuidv4()})
  }
  searchNewCompany(payload){
    return this.post('/pandora-company-bff/searchCompany', {...payload, userId: this.userId, requestId: uuidv4()})
  }
  searchCompanyInfo(payload){
    return this.post('/pandora-company-bff/searchCompanyInfo', {...payload, userId: this.userId, requestId: uuidv4(),type:"C"})
  }

  fetchGetReportYears(payload){
    return this.post('/pandora-company-bff/getReportYears', {...payload, requestId: uuidv4()})
  }
  fetchGetReportMainBusiness(payload){
    return this.post('/pandora-company-bff/getReportMainBusiness', {...payload, requestId: uuidv4()})
  }
  fetchGetReportUrl(payload){
    return this.post('/pandora-company-bff/getUrl', {...payload, requestId: uuidv4()})
  }
  fetchGetCoreCompetenceAndFuture(payload){
    return this.post('/pandora-company-bff/getCoreCompetenceAndFuture', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyResearch(payload){
    return this.post('/pandora-company-bff/getCompanyResearch', {...payload, requestId: uuidv4()})
  }
  fetchGetReportByKeyword(payload){
    return this.post('/pandora-company-bff/getReportByKeyword', {...payload, requestId: uuidv4()})
  }
  fetchGetResearchList(payload){
    return this.post('/pandora-company-bff/getList', {...payload, requestId: uuidv4()})
  }
  fetchGetReportCAGR(payload){
    return this.post('/pandora-company-bff/getReportCAGR', {...payload, requestId: uuidv4()})
  }
  fetchGetReportCRn(payload){
    return this.post('/pandora-company-bff/getReportCRn', {...payload, requestId: uuidv4()})
  }
  fetchGetIndicatorList(payload){
    return this.post('/pandora-company-bff/getIndicatorList', {...payload, requestId: uuidv4()})
  }
  fetchGetResearchUrl(payload){
    return this.post('/pandora-company-bff/getResearchUrl', {...payload, requestId: uuidv4()})
  }
  fetchGetReportInvestment(payload){
    return this.post('/pandora-company-bff/getReportInvestment', {...payload, requestId: uuidv4()})
  }  
  fetchGetCompanyBusiness(payload){
    return this.post('/pandora-company-bff/getCompanyBusiness', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyReportBusiness(payload){
    return this.post('/pandora-company-bff/getCompanyReportBusiness', {...payload, requestId: uuidv4()})
  }
  fetchSearchBusiness(payload){
    return this.post('/pandora-company-bff/searchBusiness', {...payload, requestId: uuidv4()})
  }
  addReportBusinessComment(payload){
    return this.post('/pandora-company-bff/addReportBusinessComment', {...payload, requestId: uuidv4()})
  }
  removeReportBusinessComment(payload){
    return this.post('/pandora-company-bff/removeReportBusinessComment', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyListByBusiness(payload){
    return this.post('/pandora-company-bff/getCompanyListByBusiness', {...payload, requestId: uuidv4()})
  }


  fetchGetCompanyProfile(payload){
    return this.post('/pandora-company-bff/getCompanyProfile', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetCompanyIdByStockCode(payload){
    return this.post('/pandora-company-bff/getCompanyIdByStockCode', {...payload, requestId: uuidv4()})
  }
  fetchGetBusiness(payload){
    return this.post('/pandora-company-bff/getBusiness', {...payload, requestId: uuidv4()})
  }
  
  fetchGetCompanyPolicyList(payload){
    return this.post('/pandora-company-bff/getPolicyList', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyInfo(payload){
    return this.post('/pandora-company-bff/getPolicyDetail', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyIndicatorList(payload){
    return this.post('/pandora-company-bff/getCompanyIndicatorList', {...payload, requestId: uuidv4()})
  }
  fetchGetReportTabPage(payload){
    return this.post('/pandora-company-bff/getReportTabPage', {...payload, requestId: uuidv4()})
  }
  
  fetchGetIndustryRatioTrend(payload){
    return this.post('/pandora-company-bff/getIndustryRatioTrend', {...payload, requestId: uuidv4()})
  }
  fetchGetIndustryMentionTrend(payload){
    return this.post('/pandora-company-bff/getIndustryMentionTrend', {...payload, requestId: uuidv4()})
  }
  fetchGetIndustryArticleList(payload){
    return this.post('/pandora-company-bff/getIndustryArticleList', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyRatioTrend(payload){
    return this.post('/pandora-company-bff/getCompanyRatioTrend', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyMentionTrend(payload){
    return this.post('/pandora-company-bff/getCompanyMentionTrend', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyArticleList(payload){
    return this.post('/pandora-company-bff/getCompanyArticleList', {...payload, requestId: uuidv4()})
  }
  fetchGetBarIdByCompanyId(payload){
    return this.post('/pandora-company-bff/getBarIdByCompanyId', {...payload, requestId: uuidv4()})
  }
  fetchGetWordCloudByCompanyId(payload){
    return this.post('/pandora-company-bff/getWordCloudByCompanyId', {...payload, requestId: uuidv4()})
  }

  fetchGetbusinessPerformance(payload){
    return this.post('/pandora-company-bff/businessPerformance', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyVs(payload){
    return this.post('/pandora-company-bff/companyVs', {...payload, requestId: uuidv4()})
  }
  fetchGetSwClassList(){
    return this.post('/pandora-company-bff/getSwClassList', {requestId: uuidv4()})
  }
  fetchGetCompanyBySwId(payload){
    return this.post('/pandora-company-bff/getCompanyBySwId', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyFilterType(){
    return this.post('/pandora-company-bff/getPolicyFilter', {requestId: uuidv4()})
  }
  fetchGetEventFilterType(){
    return this.post('/pandora-event-bff/getEventTypeLevelList', {requestId: uuidv4()})
  }
  fetchSearchInvestmentOrg(payload){
    return this.post('/pandora-company-bff/searchInvestmentOrg', {...payload, requestId: uuidv4()})
  }
  fetchGetInvestmentList(payload){
    return this.post('/pandora-company-bff/getInvestmentList', {...payload, requestId: uuidv4()})
  }
  fetchGetTop10(payload){
    return this.post('/pandora-company-bff/getTop10', {...payload, requestId: uuidv4()})
  }
  fetchGetIndustry(payload){
    return this.post('/pandora-company-bff/getIndustry', {...payload, requestId: uuidv4()})
  }
  fetchGetInvestmentRotation(payload){
    return this.post('/pandora-company-bff/getInvestmentRotation', {...payload, requestId: uuidv4()})
  }
  fetchGetSubdomain(payload){
    return this.post('/pandora-company-bff/getSubdomain', {...payload, requestId: uuidv4()})
  }
  fetchGetCompanyInfoById(payload){
    return this.post('/pandora-company-bff/getCompanyInfoById', {...payload, requestId: uuidv4()})
  }
  fetchGetThisRoundInvestor(payload){
    return this.post('/pandora-company-bff/getThisRoundInvestor', {...payload, requestId: uuidv4()})
  }
  fetchGetEventHistory(payload){
    return this.post('/pandora-company-bff/getEventHistory', {...payload, requestId: uuidv4()})
  }
  fetchGetRecommend(payload){
    return this.post('/pandora-company-bff/getRecommend', {...payload, requestId: uuidv4()})
  }
  fetchGetIndustryCompare(payload){
    return this.post('/pandora-company-bff/getIndustryCompare', {...payload, requestId: uuidv4()})
  }
  fetchGetEconomicList(payload){
    return this.post('/pandora-company-bff/getPolicyParseList', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyParseList(payload){
    return this.post('/pandora-company-bff/getPolicyPage', {...payload, requestId: uuidv4()})
  }
  fetchGetUserPolicyTagOrder(payload){
    return this.post('/pandora-company-bff/getUserPolicyTagOrder', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchUpdateUserPolicyTagOrder(payload){
    return this.post('/pandora-company-bff/updateUserPolicyTagOrder', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetIndustryVein(payload){
    return this.post('/pandora-company-bff/getIndustryVein', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyParseInfo(payload){
    return this.post('/pandora-company-bff/getPolicyParseInfo', {...payload, requestId: uuidv4()})
   }
  fetchFindAnnualGdp(payload){
    return this.post('/pandora-company-bff/findAnnualGdp', {...payload, requestId: uuidv4()})
  }
  fetchFindCpi(payload){
    return this.post('/pandora-company-bff/findCpi', {...payload, requestId: uuidv4()})
  }
  fetchFindConsumption(payload){
    return this.post('/pandora-company-bff/findConsumption', {...payload, requestId: uuidv4()})
  }
  fetchFindElectricityConsumption(payload){
    return this.post('/pandora-company-bff/findElectricityConsumption', {...payload, requestId: uuidv4()})
  }
  fetchFindExchangeRate(payload){
    return this.post('/pandora-company-bff/findExchangeRate', {...payload, requestId: uuidv4()})
  }
  fetchFindExportImport(payload){
    return this.post('/pandora-company-bff/findExportImport', {...payload, requestId: uuidv4()})
  }
  fetchFindHouseholdConsumption(payload){
    return this.post('/pandora-company-bff/findHouseholdConsumption', {...payload, requestId: uuidv4()})
  }
  fetchFindLpr(payload){
    return this.post('/pandora-company-bff/findLpr', {...payload, requestId: uuidv4()})
  }
  fetchFindPmi(payload){
    return this.post('/pandora-company-bff/findPmi', {...payload, requestId: uuidv4()})
  }
  fetchFindMoney(payload){
    return this.post('/pandora-company-bff/findMoney', {...payload, requestId: uuidv4()})
  }
  fetchFindPpi(payload){
    return this.post('/pandora-company-bff/findPpi', {...payload, requestId: uuidv4()})
  }
  fetchFindShibor(payload){
    return this.post('/pandora-company-bff/findShibor', {...payload, requestId: uuidv4()})
  }
  fetchFindSocialFinancing(payload){
    return this.post('/pandora-company-bff/findSocialFinancing', {...payload, requestId: uuidv4()})
  }
  fetchFindTotalRetailSales(payload){
    return this.post('/pandora-company-bff/findTotalRetailSales', {...payload, requestId: uuidv4()})
  }
  fetchFindUnemploymentRate(payload){
    return this.post('/pandora-company-bff/findUnemploymentRate', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyNewsFilter(payload){
    return this.post('/pandora-company-bff/getPolicyNewsFilter', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyNewsGraph(payload){
    return this.post('/pandora-company-bff/getPolicyNewsGraph', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyNewsList(payload){
    return this.post('/pandora-company-bff/getPolicyNewsList', {...payload, requestId: uuidv4()})
  }
  fetchAddPolicyTopic(payload){
    return this.post('/pandora-company-bff/addPolicyTopic', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchAddPolicyTopicTop(payload){
    return this.post('/pandora-company-bff/addPolicyTopicTop', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchDeletePolicyTopicTop(payload){
    return this.post('/pandora-company-bff/deletePolicyTopicTop', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetPolicyTopicConfiguration(payload){
    return this.post('/pandora-company-bff/getPolicyTopicConfiguration', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchSavePolicyTopicConfiguration(payload){
    return this.post('/pandora-company-bff/savePolicyTopicConfiguration', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetPolicyTopicTree(payload){
    return this.post('/pandora-company-bff/getPolicyTopicTree', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetSwIndustry(payload){
    return this.post('/pandora-company-bff/getSwIndustry', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicySwIndustry(payload){
    return this.post('/pandora-company-bff/getPolicySwIndustryCount', {...payload, requestId: uuidv4()})
  }
  fetchGetIssuingDep(payload){
    return this.post('/pandora-company-bff/getIssuingDep', {...payload, requestId: uuidv4()})
  }
  fetchGetInvolveArea(payload){
    return this.post('/pandora-company-bff/getInvolveArea', {...payload, requestId: uuidv4()})
  }
  fetchGetNewInvolveArea(payload){
    return this.post('/pandora-company-bff/getAreaCount', {...payload, requestId: uuidv4()})
  }
  fetchGetInvolveCompany(payload){
    return this.post('/pandora-company-bff/getInvolveCompany', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyType(payload){
    return this.post('/pandora-company-bff/getPolicyType', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyTypeCount(payload){
    return this.post('/pandora-company-bff/getPolicyTypeCount', {...payload, requestId: uuidv4()})
  }
  fetchGetTopicPolicyList(payload){
    return this.post('/pandora-company-bff/getTopicPolicyList', {...payload, requestId: uuidv4()})
  }
  fetchGetTimeLineByTopic(payload){
    return this.post('/pandora-company-bff/getTimeLineByTopic', {...payload, requestId: uuidv4()})
  }
  fetchGetTopicPolicyInvolveAreaCount(payload){
    return this.post('/pandora-company-bff/getTopicPolicyInvolveAreaCount', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyInvolveAreaCount(payload){
    return this.post('/pandora-company-bff/getArea', {...payload, requestId: uuidv4()})
  }
  fetchGetTopicLabelCount(payload){
    return this.post('/pandora-company-bff/getTopicLabelCount', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyLabelCount(payload){
    return this.post('/pandora-company-bff/getPolicyLabelCount', {...payload, requestId: uuidv4()})
  }
  fetchGetTopicHot(payload){
    return this.post('/pandora-company-bff/getTopicHot', {...payload, requestId: uuidv4()})
  }
  fetchGetTopicPolicyInfo(payload){
    return this.post('/pandora-company-bff/getTopicPolicyInfo', {...payload, requestId: uuidv4()})
  }
  fetchGetPolicyTopicHistory(payload){
    return this.post('/pandora-company-bff/getPolicyTopicHistory', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchCheckPolicyTopic(payload){
    return this.post('/pandora-company-bff/checkPolicyTopic', {...payload, requestId: uuidv4(), userId: this.userId})
  }
  fetchGetPolicyTopicPage(payload){
    return this.post('/pandora-company-bff/getPolicyTopicPage', {...payload, requestId: uuidv4()})
  }
  fetchSavePolicyTopicSearchHistory(payload){
    return this.post('/pandora-company-bff/savePolicyTopicSearchHistory', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetPreviousMeetingList(payload){
    return this.post('/pandora-company-bff/getPreviousMeetingList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetImportMeetingList(payload){
    return this.post('/pandora-company-bff/getImportMeetingList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchPolicyHtmlDiff(payload){
    return this.post('/pandora-company-bff/policyHtmlDiff', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSearchHint(payload){
    return this.post('/pandora-company-bff/searchHint', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchKeyword(payload){
    return this.post('/pandora-company-bff/keyword', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchKeywordSearchCompany(payload){
    return this.post('/pandora-company-bff/keywordSearchCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchKeywordSearchListedCompany(payload){
    return this.post('/pandora-company-bff/keywordSearchListedCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSearchListedCompany(payload){
    return this.post('/pandora-company-bff/searchListedCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSearchNews(payload){
    return this.post('/pandora-company-bff/searchNews', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchGetArticleTop(payload){
    return this.post('/pandora-company-bff/getArticleTOP3', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetWordCloudByTags(payload){
    return this.post('/pandora-company-bff/getWordCloudByTags', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchTopCompanyEvent(payload){
    return this.post('/pandora-company-bff/topCompanyEvent', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchDistributingCompanyEventScore(payload){
    return this.post('/pandora-company-bff/distributingCompanyEventScore', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchCountCompanyEvent(payload){
    return this.post('/pandora-company-bff/countCompanyEvent', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchTopCompanyPolicy(payload){
    return this.post('/pandora-company-bff/getCompanyTopPolicy', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchCompanyPolicyProfile(payload){
    return this.post('/pandora-company-bff/getCompanyPolicyProfile', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetTechnologyCompany(payload){
    return this.post('/pandora-company-bff/getTechnologyCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetProductCompany(payload){
    return this.post('/pandora-company-bff/getProductCompany', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetCompanyProduct(payload){
    return this.post('/pandora-company-bff/getCompanyProduct', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetCompanyTechnology(payload){
    return this.post('/pandora-company-bff/getCompanyTechnology', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetIndustryTechnology(payload){
    return this.post('/pandora-company-bff/getIndustryTechnology', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetIndustryProduct(payload){
    return this.post('/pandora-company-bff/getIndustryProduct', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchSaveKeyword(payload){
    return this.post('/pandora-company-bff/saveKeyword', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchCompanyPolicyImportant(payload){
    return this.post('/pandora-company-bff/getCompanyPolicyImportant', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchWordCloudDetailsInfo(payload){
    return this.post('/pandora-company-bff/wordCloudDetails', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetAnswerByIndustryNameAndKeyword(payload){
    return this.post('/pandora-chat-bff/getAnswerByIndustryNameAndKeyword', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetAnswerByQuestion(payload){
    return this.post('/pandora-chat-bff/getAnswerByQuestion', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetAnswerStatusByQuestion(payload){
    return this.post('/pandora-chat-bff/getAnswerStatusByQuestion', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetQuestionByQuestion(payload){
    return this.post('/pandora-chat-bff/getQuestionByPid', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchGetQuestionByIndustryName(payload){
    return this.post('/pandora-chat-bff/getQuestionByIndustryName2', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchGetSubQuestionStatusByPid(payload){
    return this.post('/pandora-chat-bff/getSubQuestionStatusByPid', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchDeleteQuestion(payload){
    return this.post('/pandora-chat-bff/deleteQuestion', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchAddQuestion(payload){
    return this.post('/pandora-chat-bff/addQuestion', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchGenerateKnowledgeBasen(payload){
    return this.post('/pandora-chat-bff/generateKnowledgeBase', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchUpdateQuestion(payload){
    return this.post('/pandora-chat-bff/updateQuestion', {...payload, requestId: uuidv4(),userId: this.userId})
  }

  fetchCompanyReportAnalysis(payload){
    return this.post('/pandora-chat-bff/getCompanyReportAnalysis', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchCompanyPriceCause(payload){
    return this.post('/pandora-chat-bff/getCompanyPriceCause', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchAliPayQr(payload){
    return this.post('/pandora-company-bff/getPayQr', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  fetchPaymentStatus(payload){
    return this.post('/pandora-company-bff/checkPaymentStatus', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  tocGetUser(payload){
    return this.post('/pandora-company-bff/getUser', {...payload, requestId: uuidv4()})
  }
  changePw(payload){
    return this.post('/pandora-company-bff/changePw', {...payload, requestId: uuidv4()})
  }

  tocLogin(values){
    return this.post(`/pandora-company-bff/login`, values).then(response => {
      const {code, data} = response;
      if(code === '0'){
        this.userId = data.uid;
        this.setTokenInfo({expire_in:data.expireIn,uid:data.uid,token:data.token,refresh_token:data.refreshToken});
        this.setToken(data.token)
        this.setRefreshToken(data.refreshToken);
      }
      return response;
    })
  }
  tocSendSMSCode(payload){
    return this.post('/pandora-company-bff/sendSMSCode', {...payload, requestId: uuidv4()})
  }
  tocSignIn(payload){
    return this.post('/pandora-company-bff/signIn', {...payload, requestId: uuidv4()})
  }
  getCompanyListWithEventRank(payload){
    if(payload.type==='show'){
      return this.post('/pandora-event-bff/getCompanyListWithEventRank', { ...payload,requestId: uuidv4()})
    }else{
      return this.post('/pandora-event-bff/getInvisibleCompanyListWithEventRank', { ...payload,requestId: uuidv4()})
    }
  }
  changePwByUser(payload){
    return this.post('/pandora-company-bff/changePwByUser', {...payload, requestId: uuidv4()})
  }
  markCompanyTop(payload){
    return this.post('/pandora-event-bff/markCompanyTop', {...payload, requestId: uuidv4()})
  }
  markCompanyVisible(payload){
    return this.post('/pandora-event-bff/markCompanyVisible', {...payload, requestId: uuidv4()})
  }

  getTopEventByCompanyId(payload){
    return this.post('/pandora-event-bff/getTopEventByCompanyId', {...payload, requestId: uuidv4()})
  }
  tocSocialMedia(payload){
    return this.post('/pandora-company-bff/socialMedia', {...payload, requestId: uuidv4()})
  }
  policyDrivenList(payload){
    return this.post('/pandora-company-bff/policyDrivenList', {...payload, requestId: uuidv4()})
  }

  adminOperate(payload){
    return this.post('/pandora-company-bff/adminOperate', {...payload, requestId: uuidv4()})
  }
  getTopicPolicyListByTimeLine(payload){
    return this.post('/pandora-company-bff/getTopicPolicyListByTimeLine', {...payload, requestId: uuidv4()})
  }
  getCompanyModel(payload){
    return this.post('/pandora-company-bff/companyModel', {...payload, requestId: uuidv4()})
  }
  downloadPolicyInfo(payload){
    return this.post('/pandora-company-bff/exportPolicy',{...payload, requestId: uuidv4()})
  }
  fetcheHotInfo(payload){
    return this.post('/pandora-company-bff/getHot',{...payload, requestId: uuidv4()})
  }
  getCompanyScoreRank(payload){
    return this.post('/pandora-company-bff/getCompanyScoreRank', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  updateCompanyScoreRankStatus(payload){
    return this.post('/pandora-company-bff/updateCompanyScoreRankStatus', {...payload, requestId: uuidv4()})
  }
  getCaptcha(payload){
    return this.post('/pandora-company-bff/getCaptcha', {...payload, requestId: uuidv4()})
  }
  getCaptchaErrorNum(payload){
    return this.post('/pandora-company-bff/getCaptchaErrorNum', {...payload, requestId: uuidv4()})
  }
  checkSendSMSCode(payload){
    return this.post('/pandora-company-bff/checkSendSMSCode', {...payload, requestId: uuidv4()})
  }
  checkCaptchaImage(payload){
    return this.post('/pandora-company-bff/checkCaptchaImage', {...payload, requestId: uuidv4()})
  }
  fetchCompanyPolicyImportantDetail(payload){
    return this.post('/pandora-company-bff/getCompanyPolicyImportantDetail', {...payload, requestId: uuidv4()})
  }
  saveCompanySearch(payload){
    return this.post('/pandora-company-bff/saveCompanySearch', {...payload, requestId: uuidv4(),userId: this.userId,type:'C'})
  }
  getUserOptionalStockList(payload){
    return this.post('/pandora-company-bff/getUserOptionalStockList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  checkCoupon(payload){
    return this.post('/pandora-company-bff/checkCoupon', {...payload, requestId: uuidv4()})
  }
  getCheckRefresh(payload){
    return this.post('/pandora-company-bff/checkRefresh', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  getStockAnnouncementList(payload){
    return this.post('/pandora-company-bff/getStockAnnouncementList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  getCompanyNewsList(payload){
    return this.post('/pandora-company-bff/getCompanyNewsList', {...payload, requestId: uuidv4(),userId: this.userId})
  }
  getNewsEvent(payload){
    return this.post('/pandora-company-bff/getNewsEvent', {...payload, requestId: uuidv4()})
  }
  getStockAnnouncementDetail(payload){
    return this.post('/pandora-company-bff/getStockAnnouncementDetail', {...payload, requestId: uuidv4()})
  }
  getCompanyNewsDetail(payload){
    return this.post('/pandora-company-bff/getCompanyNewsDetail', {...payload, requestId: uuidv4()})
  }
  getUserVip(payload){
    return this.post('/pandora-company-bff/getVip', {...payload, requestId: uuidv4()})
  }
  querySwFirstLevel(payload){
    return this.post('/pandora-company-bff/querySwFirstLevel', {...payload, requestId: uuidv4()})
  }
  getIndustryRank(payload){
    return this.post('/pandora-company-bff/getIndustryRank', {...payload, requestId: uuidv4()})
  }
  getStockReport(payload){
    return this.post('/pandora-company-bff/getStockReport', {...payload, requestId: uuidv4()})
  }
  getUserMedal(payload){
    return this.post('/pandora-company-bff/getUserMedal', {...payload, requestId: uuidv4()})
  }
  getUserTrend(payload){
    return this.post('/pandora-company-bff/getUserTrend', {...payload, requestId: uuidv4()})
  }
  getUserMedalSwitch(payload){
    return this.post('/pandora-company-bff/getUserMedalSwitch', {...payload, requestId: uuidv4()})
  }
  setUserMedalSwitch(payload){
    return this.post('/pandora-company-bff/setUserMedalSwitch', {...payload, requestId: uuidv4()})
  }

}

export default Pandora;